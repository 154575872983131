import { set } from "lodash";
import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { IMAGE_PATH_URL } from "../constants/path";
import { getAllPages } from '../services/api/api.service'

function LimitData(arr){
  var arrlenght = arr.length
  var darr = []
  for(var i=0;i<arrlenght;i++){
    if(i==6){
      break
    }
    darr[i]=arr[i]
  }
  return darr
}
const Header = () => {
  const [data, setData] = useState(false)
  const [pages, setPages] = useState([])
  useEffect(()=>{
    getAllPages()
    .then((e)=>{
      if(e.data.status == 200) {
        setPages(LimitData(e.data.data))
      }
    })
  },[])
  let lrNumberInLocal=localStorage.getItem('LrNumber')


  const [sticky, setSticky] = useState("");

  // on render, set listener
  useEffect(() => {
    console.log("hello");
    window.addEventListener("scroll", isSticky);
    return () => {
      window.removeEventListener("scroll", isSticky);
    };
  }, []);

  const isSticky = () => {
    /* Method that will fix header after a specific scrollable */
    const scrollTop = window.scrollY;
    const stickyClass = scrollTop >= 250 ? "fix_header" : "";
    setSticky(stickyClass);
    console.log(stickyClass);
  };

  const classes = `container-fluid header_section ${sticky}`;
  


  // useEffect(()=>{
    
  // },[])
  return (
    <div className={classes} >
      <div className="container">
        <div className="row">
          <div className="col-sm-2 header_logo">
            <a href="/">
            <img className="" src={IMAGE_PATH_URL+"logo.png"} alt="Retro Packers and Movers" />
            </a>
          </div>
          {/*  */}
          <button onClick={() => setData(!data)} id="menu_icon"><i class="fa fa-bars" aria-hidden="true"></i></button>
          {/*  */}
          {/* className={`myClass ${index ? "active" : ""}`} */}
          {/* {data ? "" : */}
          <div className={data ? 'col-sm-10 nav_menu responsive ' : 'col-sm-10 nav_menu'}>
            {/* <div className="col-sm-8 nav_menu"> */}
            <ul>
              <li>
                <Link to="/">
                  <i className="fa fa-home" aria-hidden="true"></i>
                </Link>
              </li>
              <li>
                <a href="/">Home</a> </li>
              <li>
                <a href="/about-us">About</a> </li>
              <li>
                <a href={'#'}>Services</a>
                  <ul>
                    {pages
                    .filter((e)=>e.page_type == 'services')
                    .map((e)=><li onClick={() => setData(false)}><a href={`/services/${e.page_slug}`}>{e.page_title}</a></li>)}
                  </ul>
                </li>
              <li>
                <a href="/contact-us">Contact us</a></li>

              <li>
                {
                  lrNumberInLocal===null? <a href="/login">Find Documents</a>:<a href="/ConsignerDetails">Find Documents</a>
                }
                {/* <Link to="/login">Login</Link> */}
                </li>
                <li>
                <a href="/review">Review</a></li>
              <li>
                
                <div className="header_btn">
                  <a href="/contact-us">Get A Quote</a>
                </div>
              </li>
              
            </ul>
          </div>
          <div className="header_btn_responsive">
            <a href="/contact-us">Get A Quote</a>
          </div>
          {/* } */}
        </div>
      </div>
    </div>
  );
};

export default Header;
