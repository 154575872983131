import React, { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet';
import { any, func, string } from 'prop-types'; 
import { Footer, Header, Loader } from '../components';
import { useSelector } from 'react-redux';
import { USER_SCHOOL_ADMIN, USER_PARENT, USER_STUDENT, USER_TEACHER } from '../constants';
import { useParams } from 'react-router-dom';
import { pageDetails } from '../services/api/api.service';

const NextButton = (props) => {
  const {
    className, style, onClick,
  } = props;

  return (
    <button
      onClick={onClick}
      aria-label="Next"
      type="button"
      className={className}
      style={style}
    >
      Next
    </button>
  );
};

const BackButton = (props) => {
  const {
    className, style, onClick,
  } = props;

  return (
    <button
      onClick={onClick}
      aria-label="Back"
      type="button"
      className={className}
      style={style}
    >
      Back
    </button>
  );
};

const settings = {
  autoplay: true,
  autoplaySpeed: 5000,
  dots: true,
  fade: true,
  infinite: true,
  nextArrow: <NextButton />,
  pauseOnHover: true,
  prevArrow: <BackButton />,
  slidesToScroll: 1,
  slidesToShow: 1,
  speed: 500,
};

const PublicLayout = ({
  children, title,
}) => {
  const {
    activeDrawerMenu, drawer, user,
  } = useSelector((store) => ({
    activeDrawerMenu: store.app.activeDrawerMenu,
    drawer: store.app.drawer,
    login: false,
    user: store.user.userDetail || {},
  }));
  const isDrawerOpen = drawer === 'visible';
  const drawerClass = isDrawerOpen ? '' : 'closed-sidebar';
  const schoolName = [USER_SCHOOL_ADMIN, USER_PARENT, USER_STUDENT, USER_TEACHER].includes(user?.user_type_id) ? user.name.capitalizeEachLetter() : '';
  const [loader, setLoader] = useState(false)
  const [seo, setSeo] = useState({
    meta_title : null,
    meta_keywords: null,
    meta_description: null
  })
  const { page_name } = useParams() 
  useEffect(() => {
    setLoader(true);
    pageDetails(page_name)
      .then((e) => {
        setLoader(false);
        if (e.data.status == 200 && e.data.data.length != 0) {
          console.log(e.data, '======')
          setSeo({
            meta_title : e.data.data[0]['meta_title'],
            meta_keywords: e.data.data[0]['meta_keywords'],
            meta_description: e.data.data[0]['meta_description']
          });
        }
      })
      .catch((err) => {
        setLoader(false);
      });
  }, [page_name]);
  if(loader) {
    return <Loader />
  }
  return (
  <div className="app-container app-theme-white body-tabs-shadow">
    <Helmet>
        <title>{seo.meta_title || title}</title>
        <meta name="title" content={seo.meta_title || title} />
        <meta name="keywords" content={seo.meta_keywords} />
        <meta name="description" content={seo.meta_description} />
      </Helmet>
    <Header user={user} />
    {children}
    <Footer />
  </div>
)};

PublicLayout.propTypes = {
  children: any.isRequired,
  title: string.isRequired,
};

BackButton.propTypes = {
  className: string.isRequired,
  onClick: func.isRequired,
  style: string.isRequired,
};

NextButton.propTypes = {
  className: string.isRequired,
  onClick: func.isRequired,
  style: string.isRequired,
};

export default PublicLayout;
